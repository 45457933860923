import { action, observable, runInAction, makeObservable } from 'mobx';
import { initialize as initLaunchDarkly } from 'launchdarkly-js-client-sdk';
import type { LDClient } from 'launchdarkly-js-client-sdk';
import { z } from 'zod';
import { Organization as _Organization, ClientConfiguration } from '../types';

export type Organization = Pick<
  _Organization,
  'id' | 'paid' | 'pending' | 'memberCount' | 'pendingAdminApproval'
> &
  Partial<Pick<_Organization, 'isContacts'>>;

export const sortRepresentativeOrgsForWebsockets = (
  orgs: (undefined | Organization)[]
): Organization[] => {
  const groups = orgs
    .filter(
      (org): org is Organization =>
        typeof org === 'object' &&
        !Array.isArray(org) &&
        ['id', 'paid', 'pending', 'memberCount'].filter((key) => key in org).length === 4
    )
    .filter((org) => !org.isContacts)
    .reduce(
      (prev, curr) => {
        const { pending: pendingVerification, pendingAdminApproval, paid } = curr;
        const pending = pendingVerification || pendingAdminApproval;
        if (!pending && paid) prev[0].push(curr);
        if (!pending && !paid) prev[1].push(curr);
        if (pending && paid) prev[2].push(curr);
        if (pending && !paid) prev[3].push(curr);
        return prev;
      },
      [[], [], [], []] as [Organization[], Organization[], Organization[], Organization[]]
    )
    .map((group) =>
      group.sort((x, y) => {
        if (x.memberCount !== y.memberCount) return y.memberCount - x.memberCount;
        return x.id.localeCompare(y.id);
      })
    )
    .reduce((prev, curr) => prev.concat(curr));

  return groups;
};

const envKeys: Record<string, string> = {
  prod: '5f6129460c70fc0aad284e6c',
  production: '5f6129460c70fc0aad284e6c',
  uat: '5fd1574d04d4e20b1056e514',
  env1: '5fd40206dd96e30a08444cf4',
  env2: '5fd402160bfe6d0a3ac8b57b',
  env3: '5fd402211333ce09eb1960c1',
  env4: '5fd4022b1333ce09eb1960c8',
  env5: '5fd40238e37ff609c08d9b48',
  env6: '5fd402470bfe6d0a3ac8b58a',
  env7: '5fd1572ca7a8d30afe8d9988',
  env8: '5fd402521333ce09eb1960d3',
  env9: '5fd40260e37ff609c08d9b50',
};
const DEFAULT_LD_USER_KEY = 'anonymous';

const SAMPLE_RATE_FLAGS = [
  'SENTRY_LOGIN_TIMING_SAMPLE_RATE',
  'SENTRY_PERFORMANCE_COMPOSE_SAMPLE_RATE',
  'SENTRY_PERFORMANCE_MESSAGE_SEND_SAMPLE_RATE',
  'SENTRY_PERFORMANCE_TYPING_DELAY_SAMPLE_RATE',
  'SENTRY_PROFILING_SAMPLE_RATE',
];

type TCClient = {
  config: ClientConfiguration;
  configure: (config: Partial<ClientConfiguration>) => void;
  events: { isSignedIn: boolean; reconnect: () => void };
  organizations: {
    getAll: () => Organization[];
  };
  version: string;
};
type Stores = {
  desktopAppStore: {
    desktopAppVersion?: string;
  };
  messengerStore: {
    currentOrganizationId: string | null;
    organizationIds: string[];
  };
  sessionStore: { currentUserId: string };
};

export type Params = {
  allowAlertLifespan: string;
  allowAvailability: string;
  allowConversationExpiration: string;
  allowDoNotExpire: string;
  allowScimAdminSetting: string;
  allowExpireBangsCleanUp: string;
  allowGA: string;
  allowGAVC: string;
  allowKeywordInboxSearch: string;
  allowMentions: string;
  allowTypedMentions: string;
  allowNewConversationUI: string;
  allowOTP: string;
  allowPBXRoles: string;
  allowRolesPerformance: string;
  allowRTU: string;
  allowUT: string;
  allowVoipCheck: string;
  enableMessageStatusSync: string;
  enableSuki: string;
  eventsOpenTimeout: string;
  extendAutoForwardOptions: string;
  iss?: string;
  launch?: string;
  launchServiceUrl?: string;
  launchType?: 'pcm' | 'call';
  messageStatusSyncSampleRate: string;
  noSearchResultsOnEmptyQuery: string;
  readMouseActivityLogout: string;
  shouldSendReports: string;
  showCareTeamAssignments: string;
  trackConversationHanging: string;
  useLazyLoading: string;
  useWebSocketsDisableSSEFallback: string;
  wantWebSockets: string;
};

type FeatureFlag =
  | 'ALLOW_CONVERSATION_EXPIRATION'
  | 'ALLOW_ALERT_LIFESPAN'
  | 'ALLOW_DO_NOT_EXPIRE'
  | 'ALLOW_SCIM_ADMIN_SETTING'
  | 'AVAILABILITY'
  | 'CARE_TEAM_ASSIGNMENTS'
  | 'CONVERSATION_MENTIONS'
  | 'CONVERSATION_TYPED_MENTIONS'
  | 'ENABLE_MESSAGE_STATUS_SYNC'
  | 'ENABLE_SUKI'
  | 'GROUP_ALERTS'
  | 'GROUP_ALERTS_VIEW_CONVERSATION'
  | 'KEYWORD_INBOX_SEARCH'
  | 'MESSAGE_STATUS_SYNC_SAMPLE_RATE'
  | 'NEW_CONVERSATION_UI'
  | 'OTP_LOGIN'
  | 'PBX_ROLES'
  | 'READ_MOUSE_ACTIVITY_LOGOUT'
  | 'ROLES_PERFORMANCE'
  | 'RTU'
  | 'SENTRY'
  | 'SENTRY_MONITOR_RESPONSIVENESS'
  | 'SENTRY_RESPONSIVENESS_THRESHOLD_MS'
  | 'TRACK_CONVERSATION_HANGING'
  | 'USE_NEW_LD_CONTEXTS'
  | 'USE_WEBSOCKETS'
  | 'USE_WEBSOCKETS_DISABLE_SSE_FALLBACK'
  | 'USE_LAZY_LOADING'
  | 'UT'
  | 'VOIP_CHECK_EXISTING'
  | 'EXTEND_AUTO_FORWARD_OPTIONS'
  | 'NO_SEARCH_RESULTS_ON_EMPTY_QUERY'
  | 'should-enable-org-variations';

type FeatureFlags = { [flag in FeatureFlag]?: unknown };

const booleanFlag = z.boolean();
type BooleanFlag = z.infer<typeof booleanFlag>;

const sampleRate = z.number().positive().lte(1);
type SampleRate = z.infer<typeof sampleRate>;

export default class FeatureStore {
  client: TCClient;
  @observable.shallow featureFlags: undefined | FeatureFlags = undefined;
  @observable.shallow adminFeatureFlags: undefined | FeatureFlags = undefined;
  launchDarklyClient: LDClient | null;
  adminLaunchDarklyClient: LDClient | null;
  params: Params;
  representativeOrgId: undefined | string;
  stores: Stores;
  signInOrgFeaturesLoaded: Promise<void>;
  resolveSignInOrgPromise = () => {};

  constructor({ client, stores, params }: { client: TCClient; stores: Stores; params: Params }) {
    makeObservable(this);
    this.client = client;
    this.stores = stores;
    this.params = params;
    this.launchDarklyClient = null;
    this.adminLaunchDarklyClient = null;
    this.signInOrgFeaturesLoaded = new Promise((resolve) => {
      this.resolveSignInOrgPromise = resolve;
    });
  }

  mounted() {
    this.initializeLaunchDarkly();
    this.initializeAdminLaunchDarkly();
  }

  @action('FeatureStore.syncFlagsWithSDK') syncFlagsWithSDK = (isAdminCalling?: boolean) => {
    const featureFlags = isAdminCalling ? this.adminFeatureFlags : this.featureFlags;

    const {
      ALLOW_ALERT_LIFESPAN,
      ALLOW_CONVERSATION_EXPIRATION,
      AVAILABILITY,
      CARE_TEAM_ASSIGNMENTS,
      CONVERSATION_MENTIONS,
      CONVERSATION_TYPED_MENTIONS,
      ENABLE_MESSAGE_STATUS_SYNC,
      ENABLE_SUKI,
      EXTEND_AUTO_FORWARD_OPTIONS,
      GROUP_ALERTS_VIEW_CONVERSATION,
      GROUP_ALERTS,
      KEYWORD_INBOX_SEARCH,
      MESSAGE_STATUS_SYNC_SAMPLE_RATE,
      NEW_CONVERSATION_UI,
      NO_SEARCH_RESULTS_ON_EMPTY_QUERY,
      OTP_LOGIN,
      PBX_ROLES,
      READ_MOUSE_ACTIVITY_LOGOUT,
      ROLES_PERFORMANCE,
      RTU,
      TRACK_CONVERSATION_HANGING,
      USE_LAZY_LOADING,
      USE_WEBSOCKETS_DISABLE_SSE_FALLBACK,
      USE_WEBSOCKETS,
      UT,
      VOIP_CHECK_EXISTING,
    } = featureFlags || {};

    const useWebSockets = this.params.wantWebSockets
      ? this.params.wantWebSockets === 'true'
      : Boolean(USE_WEBSOCKETS);

    const useWebSocketsDisableSSEFallback = this.params.useWebSocketsDisableSSEFallback
      ? this.params.useWebSocketsDisableSSEFallback === 'true'
      : Boolean(USE_WEBSOCKETS_DISABLE_SSE_FALLBACK);

    let eventsOpenTimeout: number | undefined = undefined;
    if (this.params.eventsOpenTimeout) {
      const value = parseInt(this.params.eventsOpenTimeout, 10);
      if (typeof value === 'number' && !isNaN(value)) eventsOpenTimeout = value;
    }

    let shouldSendReports: boolean | undefined = undefined;
    if (this.params.shouldSendReports) {
      shouldSendReports = this.params.shouldSendReports === 'true';
    }

    const enableMessageStatusSync = this.params.enableMessageStatusSync
      ? this.params.enableMessageStatusSync === 'true'
      : Boolean(ENABLE_MESSAGE_STATUS_SYNC);

    const messageStatusSyncSampleRate = this.params.messageStatusSyncSampleRate
      ? parseFloat(this.params.messageStatusSyncSampleRate)
      : typeof MESSAGE_STATUS_SYNC_SAMPLE_RATE === 'number'
      ? MESSAGE_STATUS_SYNC_SAMPLE_RATE
      : 0;

    const shouldReconnect =
      useWebSockets !== this.client.config.useWebSockets && this.client.events.isSignedIn;

    this.client.configure({
      allowAlertLifespan: !!ALLOW_ALERT_LIFESPAN || this.params.allowAlertLifespan === 'true',
      allowConversationExpiration:
        !!ALLOW_CONVERSATION_EXPIRATION || this.params.allowConversationExpiration === 'true',
      allowAvailability: !!AVAILABILITY || this.params.allowAvailability === 'true',
      allowMentions: !!CONVERSATION_MENTIONS || this.params.allowMentions === 'true',
      allowTypedMentions:
        !!CONVERSATION_TYPED_MENTIONS || this.params.allowTypedMentions === 'true',
      allowNewConversationUI:
        !!NEW_CONVERSATION_UI || this.params.allowNewConversationUI === 'true',
      allowKeywordInboxSearch:
        !!KEYWORD_INBOX_SEARCH || this.params.allowKeywordInboxSearch === 'true',
      allowRTU: !!RTU || this.params.allowRTU === 'true',
      allowUT: !!UT || this.params.allowUT === 'true',
      allowGA: !!GROUP_ALERTS || this.params.allowGA === 'true',
      allowGAVC: !!GROUP_ALERTS_VIEW_CONVERSATION || this.params.allowGAVC === 'true',
      allowOTP: !!OTP_LOGIN || this.params.allowOTP === 'true',
      allowPBXRoles: !!PBX_ROLES || this.params.allowPBXRoles === 'true',
      allowVoipCheck: !!VOIP_CHECK_EXISTING || this.params.allowVoipCheck === 'true',
      allowRolesPerformance: !!ROLES_PERFORMANCE || this.params.allowRolesPerformance === 'true',
      isFHIRLaunchSession: !!(this.params.launch && this.params.iss),
      enableSuki: !!ENABLE_SUKI || this.params.enableSuki === 'true',
      enableMessageStatusSync,
      ...(eventsOpenTimeout && { eventsOpenTimeout }),
      lazyLoading: !!USE_LAZY_LOADING || this.params.useLazyLoading === 'true',
      messageStatusSyncSampleRate,
      ...(shouldSendReports && { shouldSendReports }),
      showCareTeamAssignments:
        !!CARE_TEAM_ASSIGNMENTS || this.params.showCareTeamAssignments === 'true',
      useWebSockets,
      useWebSocketsDisableSSEFallback,
      trackConversationHanging: !!TRACK_CONVERSATION_HANGING,
      readMouseActivityLogout: !!READ_MOUSE_ACTIVITY_LOGOUT,
      extendAutoForwardOptions:
        !!EXTEND_AUTO_FORWARD_OPTIONS || this.params.extendAutoForwardOptions === 'true',
      noSearchResultsOnEmptyQuery: !!NO_SEARCH_RESULTS_ON_EMPTY_QUERY,
    });

    if (shouldReconnect) this.client.events.reconnect();
  };

  @action('FeatureStore.initializeLaunchDarkly') initializeLaunchDarkly = () => {
    if (this.launchDarklyClient) {
      console.warn('LaunchDarkly Client has already been initialized.');
      return;
    }

    const envKey = envKeys[this.client.config.apiEnv] || envKeys.prod;
    this.launchDarklyClient = initLaunchDarkly(envKey, {
      key: DEFAULT_LD_USER_KEY,
    });

    this.launchDarklyClient.on('ready', () => {
      runInAction(() => {
        this.featureFlags = this.getAllFlags();
        this.syncFlagsWithSDK();
      });
    });

    this.launchDarklyClient.on('change:USE_WEBSOCKETS', this.handleWebSocketsChangeEvent);

    SAMPLE_RATE_FLAGS.forEach((flag) =>
      this.launchDarklyClient?.on(`change:${flag}`, this.sampleChangeCallback(flag))
    );
  };

  @action('FeatureStore.initializeAdminLaunchDarkly') initializeAdminLaunchDarkly = () => {
    if (this.adminLaunchDarklyClient) {
      console.warn('AdminLaunchDarkly Client has already been initialized.');
      return;
    }

    const envKey = envKeys[this.client.config.apiEnv] || envKeys.prod;
    this.adminLaunchDarklyClient = initLaunchDarkly(envKey, {
      key: DEFAULT_LD_USER_KEY,
    });

    this.adminLaunchDarklyClient.on('ready', () => {
      runInAction(() => {
        this.adminFeatureFlags = this.getAdminAllFlags();
        this.syncFlagsWithSDK(true);
      });
    });
  };

  @action('FeatureStore.handleWebSocketsChangeEvent') handleWebSocketsChangeEvent = (
    change: BooleanFlag
  ) => {
    const parsed = booleanFlag.safeParse(change);
    if (!parsed.success) {
      console.error('Failed to parse USE_WEBSOCKETS change event');
      return;
    }
    runInAction(() => {
      this.featureFlags = { ...this.featureFlags, USE_WEBSOCKETS: parsed.data };
      this.syncFlagsWithSDK();
    });
  };

  @action('FeatureStore.sampleChangeCallback') sampleChangeCallback = (sampleFlag: string) => {
    return (change: SampleRate) => {
      const parsed = sampleRate.safeParse(change);
      if (!parsed.success) {
        console.error(`Failed to parse ${sampleFlag} change event`);
        return;
      }
      runInAction(() => {
        this.featureFlags = { ...this.featureFlags, [sampleFlag]: parsed.data };
        this.syncFlagsWithSDK();
      });
    };
  };

  @action('FeatureStore.registerNewLDUserKey') registerNewLDUserKey = async (
    orgId?: string,
    isSignInOrg?: boolean
  ) => {
    if (
      !this.featureFlags ||
      !this.featureFlags['should-enable-org-variations'] ||
      !this.launchDarklyClient
    )
      return;

    const launchDarklyContextKey = this.launchDarklyClient.getContext().key;
    if (launchDarklyContextKey === orgId) {
      if (isSignInOrg) this.resolveSignInOrgPromise();
      return;
    }

    const orgs = this.client.organizations.getAll();
    const sortedOrgs: (Organization | undefined)[] = sortRepresentativeOrgsForWebsockets(orgs);
    const representativeOrgId = sortedOrgs[0]?.id || '_no_org';
    const userId = this.stores.sessionStore.currentUserId;
    this.representativeOrgId = representativeOrgId;
    this.client.configure({ representativeOrgId: this.representativeOrgId });

    const daVersion = this.stores.desktopAppStore.desktopAppVersion;
    const ldContext: {
      'DA Version'?: string;
      key: string;
      kind: 'user' | 'organization';
      'Representative Org ID': string;
      'Org IDs': string[];
      'User ID': string;
      Version: string;
    } = {
      ...(daVersion && { 'DA Version': daVersion }),
      key: orgId || DEFAULT_LD_USER_KEY,
      kind: this.featureFlags?.USE_NEW_LD_CONTEXTS ? 'organization' : 'user',
      'Representative Org ID': representativeOrgId,
      'Org IDs': orgs.map((org) => org.id),
      'User ID': userId,
      Version: this.client.version,
    };

    const newFlags = await this.launchDarklyClient.identify(ldContext);
    if (newFlags.USE_NEW_LD_CONTEXTS && ldContext.kind !== 'organization') {
      ldContext.kind = 'organization';
      const flagsFromNewContext = await this.launchDarklyClient.identify(ldContext);
      runInAction(() => {
        this.featureFlags = flagsFromNewContext;
        this.syncFlagsWithSDK();
      });
    } else {
      runInAction(() => {
        this.featureFlags = newFlags;
        this.syncFlagsWithSDK();
      });
    }
    if (isSignInOrg) this.resolveSignInOrgPromise();
  };

  @action('FeatureStore.registerAdminNewLDUserKey') registerAdminNewLDUserKey = async (
    orgId: string
  ) => {
    if (
      !this.adminFeatureFlags ||
      !this.adminFeatureFlags['should-enable-org-variations'] ||
      !this.adminLaunchDarklyClient
    )
      return;

    const launchDarklyContextKey = this.adminLaunchDarklyClient.getContext().key;
    if (launchDarklyContextKey === orgId) return;

    const userId = this.stores.sessionStore.currentUserId;
    this.representativeOrgId = orgId;
    this.client.configure({ representativeOrgId: this.representativeOrgId });

    const daVersion = this.stores.desktopAppStore.desktopAppVersion;
    const ldContext: {
      'DA Version'?: string;
      key: string;
      kind: 'user' | 'organization';
      'Representative Org ID': string;
      'Org IDs': string[];
      'User ID': string;
      Version: string;
    } = {
      ...(daVersion && { 'DA Version': daVersion }),
      key: orgId || DEFAULT_LD_USER_KEY,
      kind: this.adminFeatureFlags?.USE_NEW_LD_CONTEXTS ? 'organization' : 'user',
      'Representative Org ID': orgId,
      'Org IDs': [orgId],
      'User ID': userId,
      Version: this.client.version,
    };

    const newFlags = await this.adminLaunchDarklyClient.identify(ldContext);
    if (newFlags.USE_NEW_LD_CONTEXTS && ldContext.kind !== 'organization') {
      ldContext.kind = 'organization';
      const flagsFromNewContext = await this.adminLaunchDarklyClient.identify(ldContext);
      runInAction(() => {
        this.adminFeatureFlags = flagsFromNewContext;
        this.syncFlagsWithSDK(true);
      });
    } else {
      runInAction(() => {
        this.adminFeatureFlags = newFlags;
        this.syncFlagsWithSDK(true);
      });
    }
  };

  @action('FeatureStore.getAllFlags') getAllFlags = () => {
    if (this.launchDarklyClient === null) {
      console.error('LaunchDarkly Client Not Initialized.');
      return;
    }
    return this.launchDarklyClient.allFlags();
  };

  @action('FeatureStore.getAdminAllFlags') getAdminAllFlags = () => {
    if (this.adminLaunchDarklyClient === null) {
      console.error('AdminLaunchDarkly Client Not Initialized.');
      return;
    }
    return this.adminLaunchDarklyClient.allFlags();
  };

  @action('FeatureStore.getURLParams') getURLParams = () => {
    return this.params;
  };
}
