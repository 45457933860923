import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../common/bem';
import { UndoComponent } from '../../../common/components';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';
import PatientSidebarTabs from '../../../models/enums/PatientSidebarTabs';
import AppTypes from '../../../models/enums/AppTypes';
import { AlertCardsView } from '../../../common/components/Alerts';
import { CallLogView } from './CallLogView';
import { ConversationPane, InfoButton } from './ConversationPane';
import { Sidebar } from './Sidebar';
import { SearchSidebar } from './SearchSidebar';
import KeywordSearchSideBar from './Sidebar/KeywordSearch/KeywordSearchSideBar';
import InfoPane from './InfoPane';
import TeamProfile from './TeamProfile';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('Messenger');

const Messenger = ({
  currentAppSelected,
  DevTools,
  hasCurrentConversation,
  currentConversation,
  isComposing,
  isHidden,
  isInfoPaneOpen,
  isPrintModeOpen,
  isProviderNetwork,
  isSearching,
  isSignOutLoading,
  isTeamProfileOpen,
  selectedFilterBar,
  setPatientFilteredConversations,
  sidebarTab,
}) => {
  const isKeywordSearchModeOn = useAppSelector(
    (state) => state.keywordSearch.isKeywordSearchModeOn
  );

  if (isSignOutLoading) return null;

  const isCallLog = !isProviderNetwork && sidebarTab === PatientSidebarTabs.CALLS;
  const isAlertCardsView = isProviderNetwork && selectedFilterBar === 'Alerts';
  const isKeywordSearchAvailable =
    isKeywordSearchModeOn && !isSearching && isProviderNetwork && !isComposing;

  return (
    <div className={classes({ isHidden })}>
      <div className={classes('content', { isPrintModeOpen })}>
        <div className={classes('sidebar-content')}>
          {currentAppSelected === AppTypes.MESSAGES && !isKeywordSearchModeOn && <Sidebar />}
          {isSearching && !setPatientFilteredConversations && <SearchSidebar />}
          {isKeywordSearchAvailable && <KeywordSearchSideBar />}
        </div>
        <div className={classes('middle-and-info')}>
          {isTeamProfileOpen ? (
            <TeamProfile />
          ) : isCallLog ? (
            <CallLogView />
          ) : isAlertCardsView ? (
            <AlertCardsView />
          ) : (
            <div className={classes('middle-content', { isInfoPaneOpen })}>
              <ConversationPane currentConversation={currentConversation} />
              <UndoComponent />
            </div>
          )}
          <div className={classes('info-pane', { isInfoPaneOpen })}>
            <InfoPane />
          </div>
          {!isComposing &&
            hasCurrentConversation &&
            !isTeamProfileOpen &&
            !isCallLog &&
            !isAlertCardsView && (
              <div className={classes('info-button')}>
                <InfoButton />
              </div>
            )}
        </div>
      </div>
      <DevTools />
    </div>
  );
};

Messenger.propTypes = {
  currentAppSelected: PropTypes.string.isRequired,
  DevTools: PropTypes.func.isRequired,
  hasCurrentConversation: PropTypes.bool.isRequired,
  isComposing: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  isInfoPaneOpen: PropTypes.bool.isRequired,
  isPrintModeOpen: PropTypes.bool.isRequired,
  isProviderNetwork: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isSignOutLoading: PropTypes.bool.isRequired,
  isTeamProfileOpen: PropTypes.bool.isRequired,
  selectedFilterBar: PropTypes.string,
  setPatientFilteredConversations: PropTypes.bool.isRequired,
  sidebarTab: PropTypes.string,
};

export default mobxInjectSelect({
  composeMessageStore: ['isComposing'],
  conversationStore: [
    'hasCurrentConversation',
    'setPatientFilteredConversations',
    'currentConversation',
  ],
  messengerStore: ['isInfoPaneOpen', 'isPrintModeOpen', 'isSearching', 'currentAppSelected'],
  rosterStore: ['selectedFilterBar', 'selectedFilter'],
  sessionStore: ['isSignOutLoading'],
  staticStore: ['DevTools'],
  teamStore: ['isTeamProfileOpen'],
  networkStore: ['isProviderNetwork'],
  patientStore: ['sidebarTab'],
})(Messenger);
