import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Linkify from 'tt-react-linkify';
import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { MessageSubTypes } from '../../../../models/enums/MessageSubTypes';
import { isSingleEmoji, phone } from '../../../utils';
import MarkdownWrapper from '../../MarkdownWrapper';
import { filterOutCorruptedMentionsData } from './filterOutCorruptedMentionsData';
import { MessageForwardBody, MessageNormalBody } from './';

const TRUNCATE_BODY_TO_CHARS = 100;
const classes = BEM.with('MessageContent');

class MessageBody extends Component {
  static propTypes = {
    composeToContacts: PropTypes.func.isRequired,
    composeToEntityV2: PropTypes.func.isRequired,
    currentUserId: PropTypes.string.isRequired,
    findRole: PropTypes.func.isRequired,
    findUser: PropTypes.func.isRequired,
    isMentionsEnabled: PropTypes.bool,
    isModal: PropTypes.bool,
    isProviderNetwork: PropTypes.bool,
    logPendoAnalytics: PropTypes.func.isRequired,
    message: propTypes.message.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    richTextFormat: PropTypes.bool.isRequired,
  };

  render() {
    const { isModal, message, multiSelectable } = this.props;
    let { body } = message;
    const { isForwarded } = message;

    if (!isSingleEmoji(body)) {
      body = this._generateBody();
    }

    if (isForwarded && !isModal) {
      return (
        <MessageForwardBody bodyText={body} message={message} multiSelectable={multiSelectable} />
      );
    } else {
      return (
        <MessageNormalBody bodyText={body} message={message} multiSelectable={multiSelectable} />
      );
    }
  }

  _messageCaller = (phoneNumber) => {
    const { composeToContacts } = this.props;
    return () => composeToContacts(phoneNumber);
  };

  _generateBody = () => {
    const {
      composeToEntityV2,
      currentUserId,
      findRole,
      findUser,
      isMentionsEnabled,
      isModal,
      isProviderNetwork,
      logPendoAnalytics,
      message,
      openModal,
      richTextFormat,
    } = this.props;
    const { body, metadata, originalMetadata, sender, subType } = message;

    const mentionsMetadata = originalMetadata ? originalMetadata : metadata;

    const mentionsPayload = mentionsMetadata?.find(
      (x) => x.namespace === 'tigertext:message:mentions'
    )?.payload;

    const sortedMentionsPayload = mentionsPayload
      ?.slice()
      .sort((a, b) => (a.start > b.start ? 1 : -1));
    const inlineImages = !isModal;
    const phoneMatch = body && !isModal && body.match(/\+?1?[-. ]?\(?\d{3}\)? ?-?\d{3}-\d{4}\b/g);

    if (
      isMentionsEnabled &&
      isProviderNetwork &&
      sortedMentionsPayload &&
      sortedMentionsPayload.length > 0
    ) {
      let currentIndex = 0;
      const filterMentionsData = filterOutCorruptedMentionsData(sortedMentionsPayload, body).map(
        ({ start, end, entity_id, entity_type }) => {
          const elements = [];

          async function createComposeFlowWithEntity() {
            const entity =
              entity_type === 'user'
                ? await findUser(entity_id, message.recipientOrganizationId)
                : entity_type === 'role'
                ? await findRole(entity_id, message.recipientOrganizationId, {
                    ignoreNotFound: true,
                  })
                : undefined;

            if (entity && !entity.removedFromOrg) {
              composeToEntityV2(entity);
            } else {
              openModal('error', {
                customBodyText: 'This recipient no longer exists',
              });
            }
          }

          try {
            if (start > currentIndex) {
              elements.push(body.substring(currentIndex, start));
            }
            elements.push(
              <span
                onClick={async () => {
                  logPendoAnalytics({
                    tracker: {
                      name: `Inbox | Messenger - Mention Click`,
                      props: {
                        MentionType: entity_type,
                      },
                    },
                  });
                  await createComposeFlowWithEntity();
                }}
                className={classes('mention', {
                  isMentionedCurrentUser: currentUserId === entity_id,
                })}
                key={start + end + entity_id + entity_type}
              >
                {body.substring(start, end)}
              </span>
            );

            currentIndex = end;
          } catch (e) {
            console.error('e ', e);
          }
          return elements;
        }
      );

      const jsxBody = filterMentionsData.flat() || [];

      if (currentIndex < body.length) {
        jsxBody.push(body.substring(currentIndex));
      }

      return (
        <Linkify
          inlineImages={inlineImages}
          properties={{ rel: ['noopener noreferrer'], target: '_blank' }}
        >
          {jsxBody}
        </Linkify>
      );
    }

    if (subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE) {
      return 'The invite has been sent';
    } else if (isModal) {
      if (body.length > TRUNCATE_BODY_TO_CHARS) {
        return body.substr(0, TRUNCATE_BODY_TO_CHARS) + '...';
      } else {
        return body;
      }
    } else if (sender && sender.displayName === 'TigerPage' && phoneMatch) {
      const phoneNumber = phoneMatch.map((phonePart) => phone.formatPhoneWithDash(phonePart));
      const splitText = body.split(/\+?1?[-. ]?\(?\d{3}\)? ?-?\d{3}-\d{4}\b/);

      if (phoneNumber.length > 1) {
        return (
          <Linkify
            inlineImages={inlineImages}
            properties={{ rel: ['noopener noreferrer'], target: '_blank' }}
          >
            {splitText[0] + ' '}
            <span className={classes('phoneNumber')} onClick={this._messageCaller(phoneNumber[0])}>
              {phoneNumber[0]}
            </span>
            {splitText[1]}
            <br />
            <span className={classes('phoneNumber')} onClick={this._messageCaller(phoneNumber[1])}>
              {phoneNumber[1]}
            </span>
            {splitText[2]}
          </Linkify>
        );
      } else {
        return (
          <Linkify
            inlineImages={inlineImages}
            properties={{ rel: ['noopener noreferrer'], target: '_blank' }}
          >
            {splitText[0] + ' '}
            <span className={classes('phoneNumber')} onClick={this._messageCaller(phoneNumber[0])}>
              {phoneNumber[0]}
            </span>
            {splitText[1]}
          </Linkify>
        );
      }
    } else {
      let renderedBody = (
        <Linkify
          inlineImages={inlineImages}
          properties={{ rel: ['noopener noreferrer'], target: '_blank' }}
        >
          {body}
        </Linkify>
      );
      if (richTextFormat) {
        renderedBody = <MarkdownWrapper text={body} />;
      }
      return renderedBody;
    }
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['composeToEntityV2'],
  localStore: ['richTextFormat'],
  messengerStore: ['isMentionsEnabled'],
  modalStore: ['openModal'],
  networkStore: ['isProviderNetwork'],
  roleStore: ['findRole'],
  sessionStore: ['currentUserId'],
  trackerStore: ['logPendoAnalytics'],
  userStore: ['findUser'],
})(MessageBody);
