import { action, observable, makeObservable } from 'mobx';

export default class OrganizationSelectorStore {
  @observable isOpenSelector = false;

  constructor() {
    makeObservable(this);
  }

  @action('OrganizationSelectorStore.openSelector') openSelector = () => {
    this.isOpenSelector = true;
  };

  @action('OrganizationSelectorStore.closeSelector') closeSelector = () => {
    this.isOpenSelector = false;
  };
}
