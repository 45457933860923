import { format } from 'date-fns';
import { isCurrentUser, renderBody, renderAvatar, getStoreModel } from './helper';
import BEM from 'common/bem';
import { User } from 'types';
import { ReactComponent as AttachmentIcon } from 'common/images/attach_file.svg';
import { ReactComponent as KeywordResultArrow } from 'common/images/keyword-result-arrow.svg';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import { actions, useAppDispatch } from 'redux-stores';

const classes = BEM.with('KeywordSearchSideBar');

const { setSelectedMessageId } = actions;

type KeywordMessageBubbleMobXProps = {
  currentOrganizationId: string;
  getEntityData: (entityType: string, id: string) => User;
  setCurrentConversationId: (id: string) => void;
  navigateToConversation: (modelType: string, id: string, currentOrganizationId: string) => void;
};

interface KeywordMessageBubbleProps {
  body: string;
  conversationId?: string;
  conversationMetadata: {
    peer_token: string;
    group_id: string;
    is_dl_list: boolean;
  };
  createdTime: string;
  currentUserId: string;
  highlight: {
    body: string;
    attachment_name: string | null;
  };
  isGroup: boolean;
  isSecondLevel?: boolean;
  messageId?: string;
  myRoleIds: string[];
  senderDisplayName?: string;
  senderToken: string;
}

const KeywordMessageBubble = ({
  body,
  currentOrganizationId,
  conversationMetadata,
  createdTime,
  currentUserId,
  getEntityData,
  highlight,
  isGroup,
  isSecondLevel,
  messageId,
  myRoleIds,
  senderDisplayName,
  senderToken,
  navigateToConversation,
}: KeywordMessageBubbleProps & KeywordMessageBubbleMobXProps) => {
  const isMe = isCurrentUser(currentUserId, senderToken, myRoleIds);
  const sender = getStoreModel('user', senderToken, getEntityData) as User;

  const dispatch = useAppDispatch();

  const openSelectedConversation = async () => {
    const { group_id, peer_token, is_dl_list } = conversationMetadata || {};
    const id = group_id || peer_token;
    const modelType = group_id ? 'group' : is_dl_list ? 'distributionList' : 'user';

    await navigateToConversation(modelType, id, currentOrganizationId);

    if (messageId) {
      dispatch(setSelectedMessageId(messageId));
    }
  };

  return (
    <div className={classes('message-container')}>
      {(!isSecondLevel || (isSecondLevel && isGroup)) && (
        <div className={classes('avatar-container')}>
          {!isMe && <div className={classes('avatar')}>{renderAvatar(sender)}</div>}
        </div>
      )}
      <div
        className={classes('message-bubble', {
          isCurrentUser: isMe,
        })}
        onClick={openSelectedConversation}
      >
        {isGroup && !isMe && (
          <div className={classes('user-name')}>
            {senderDisplayName || sender?.displayName || 'Name not available'}
          </div>
        )}
        {renderBody(highlight.body, body)}
        {highlight.attachment_name && (
          <div className={classes('attachment-container')}>
            <AttachmentIcon />
            <div
              className={classes('attachment-name')}
              dangerouslySetInnerHTML={{ __html: highlight.attachment_name }}
            />
          </div>
        )}
        <div className={classes('message-created-time')}>
          {format(new Date(createdTime), 'M/d/yy h:mm a')}
        </div>
      </div>
      <div className={classes('arrow')} onClick={openSelectedConversation}>
        <KeywordResultArrow />
      </div>
    </div>
  );
};

export default mobxInjectSelect<KeywordMessageBubbleProps, KeywordMessageBubbleMobXProps>({
  conversationStore: ['navigateToConversation'],
  entityStore: ['getEntityData'],
  messengerStore: ['currentOrganizationId'],
})(KeywordMessageBubble);
